import React from 'react';
import ScrollToTop from "react-scroll-to-top";

function BackTopBack() {
  return (
    <div>
      <ScrollToTop smooth />
    </div>
  );
} 
export default BackTopBack;