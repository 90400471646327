import React from "react";
import "./loader.css"

function Loader() {
  return (
    <div className="center-div ">
      <span class="loader"></span>
    </div>
  );
}

export default Loader